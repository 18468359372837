/*!
 * SPDX-FileCopyrightText: 2022 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.user-line-name {
  text-overflow: ellipsis;
  flex: 1 1;
  overflow: hidden;
}

.user-image {
  color: transparent;
}
