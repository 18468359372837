/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

@mixin button($color) {
  color: #ffffff;
  background-color: $color;
  &:hover {
    background-color: darken($color, 15%);
  }
}

.btn-social-github {
  @include button(#444444);
}

.btn-social-gitlab {
  @include button(#FA7035);
}

.btn-social-google {
  @include button(#DD4B39);
}
